<template>
  <div id="misc">
    <v-row align="center" class="mb-3">
      <v-col cols="12" md="4">
        <v-text-field
          v-model="query"
          :prepend-inner-icon="icons.mdiMagnify"
          class="app-bar-search flex-grow-0"
          dense
          hide-details
          outlined
          rounded
        ></v-text-field>
      </v-col>

    </v-row>
    <v-progress-linear
      v-show="isLoading"
      class="mb-2"
      indeterminate
    ></v-progress-linear>

    <v-row>
      <v-col v-for="p in politicians" :key="p.id" cols="12" lg="3" md="4" sm="6">
        <PoliticiansCard :politician="p"/>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import {
  mdiMagnify
} from '@mdi/js'

import PoliticiansCard from "../../components/PoliticiansCard.vue"
import axios from "axios";
import config from "@/utils/config";
import _ from "lodash";

export default {
  name: "Politicians",
  components: {
    PoliticiansCard
  },
  data() {
    return {
      isLoading: false,
      politicians: [],
      query: "",
      icons: {
        mdiMagnify: mdiMagnify
      }
    }
  },
  watch: {
    query() {
      if (this.query != null && this.query !== '') {
        this.search()
      }
    }
  },
  methods: {
    updatePoliticians() {
      let component = this;
      component.isLoading = true;
      let request_url = config.AW_URL + "/candidacies-mandates?parliament_period=111";
      if (this.query) {
        request_url += "&politician[entity.label][cn]=" + this.query;
      }
      axios.get(request_url).then((data) => {
        let politicians = [];

        data.data.data.forEach(function (mandate) {
          politicians.push(mandate.politician.id);
        })
        return politicians;
      }).then((politicians) => {
        if (politicians.length) {
          axios.get(config.AW_URL + "/politicians?id[in]=[" + politicians.join(",") + "]").then((data) => {
            component.politicians = data.data.data;
          }).then((data) => {
            component.isLoading = false;
          });
        } else {
          component.politicians = [];
          component.isLoading = false;
        }
      });
    },
    search: _.debounce(function () {
      this.updatePoliticians();
    }, 500)
  },
  mounted() {
    this.updatePoliticians();
  }
}
</script>

<style scoped>

</style>
