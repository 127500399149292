<template>
  <v-card class="mx-auto">
    <v-card-text>
      <div>{{ politician.id }}</div>
      <p class="text-h5 text--primary">
        {{ politician.label }}
      </p>
      <p>{{ politician.party.label }}</p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="teal accent"
        text
        @click="reveal = true"
      >
        Learn More
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
      >
        <v-card-text class="pb-0 text--white">
          <p class="text-h4">

          </p>
          <p>
            Gender: {{ politician.sex }}<br>
            Year of birth: {{ politician.year_of_birth }}<br>
            Education: {{ politician.education }}<br>
          </p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="teal secondary me-2"
            @click="reveal = false">
            Close
          </v-btn>
          <v-dialog
            v-model="dialog"
            width="90%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="teal secondary"
                dark
                v-bind="attrs"
                @click="getArticles(politician.id)"
                v-on="on"
              >
                Articles
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Artikel für {{ politician.label }}</span>
              </v-card-title>
              <v-card-text>
                <v-progress-circular v-if="currentlyLoading"
                                     color="primary"
                                     indeterminate
                ></v-progress-circular>
                <div v-if="!currentlyLoading && !articles.length" class="text--lighten-5">No
                  articles in the dataset.
                </div>

                <v-expansion-panels v-if="!currentlyLoading">
                  <v-expansion-panel v-for="article in articles" :key="article.id">
                    <v-expansion-panel-header style="width: 100%">
                      <v-row>
                        <v-col sm="8" cols="12">{{ article.title }}</v-col>
                        <v-col cols="12" sm="2">
                          <span class="small text--lighten-5">{{ article.source }}</span>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <span class="ms-2 small text--lighten-5">
                            {{ moment(article.published).format('ll') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="small highlight" v-for="highlight in article.highlight"
                           :key="highlight" v-html="highlight"></div>
                      <a :href="article.url">Go to article</a>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="quotesDialog"
            width="90%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="teal secondary"
                dark
                v-bind="attrs"
                class="ms-2"
                @click="getQuotes(politician.id)"
                v-on="on"
              >
                Quotes
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Quotes für {{ politician.label }}</span>
              </v-card-title>
              <v-card-text>
                <v-progress-circular v-if="quotesLoading"
                                     color="primary"
                                     indeterminate
                ></v-progress-circular>
                <div v-if="!quotesLoading && !quotes.length" class="text--lighten-5">No
                  quotes in the dataset.
                </div>

                <v-expansion-panels v-if="!quotesLoading">
                  <v-expansion-panel v-for="quote in quotes" :key="quote._id">
                    <v-expansion-panel-header style="width: 100%">
                      <v-row>
                        <v-col sm="8" cols="12">{{ quote.quote }}</v-col>
                        <v-col cols="12" sm="2">
                          <span class="small text--lighten-5">{{ quote.speaker }}</span>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <span class="ms-2 small text--lighten-5">
                            {{ moment(quote.published).format('ll') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="small highlight" v-html="quote.highlight"></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="quotesDialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>

import config from "@/utils/config";
import axios from "axios";

let moment = require("moment");
moment.locale('de-DE');

export default {
  name: "PoliticiansCard",
  data() {
    return {
      reveal: false,
      dialog: false,
      quotesDialog: false,
      quotes: [],
      articles: [],
      moment: moment,
      currentlyLoading: false,
      quotesLoading: false
    }
  },
  methods: {
    getArticles(identifier) {
      this.currentlyLoading = true;
      let request_url = config.APP_API_URL + "/articles/" + identifier;
      axios.get(request_url).then((data) => {
        this.articles = data.data.articles;
        this.currentlyLoading = false;
        console.log(data)
      })
    },
    getQuotes(identifier) {
      this.quotesLoading = true;
      let request_url = config.SERVER_URL + "/quotes/politician/" + identifier;
      axios.get(request_url).then((data) => {
        this.quotes = data.data.quotes;
        this.quotesLoading = false;
        console.log(data)
      })
    }
  },
  props: {
    politician: Object
  }
}
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  background: #6e6e6e !important;
}

.text--white {
  color: #e7e7e7 !important;
}

em {
  background: #ffd235;
}

.small {
  font-size: 0.75rem;
}


</style>
